var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.loading } },
    [
      _c(
        "a-row",
        { staticClass: "card-view", attrs: { gutter: [16, 16] } },
        [
          _vm.pages.length
            ? _c(
                "draggable",
                {
                  on: { end: _vm.dragPagesEnd },
                  model: {
                    value: _vm.pages,
                    callback: function($$v) {
                      _vm.pages = $$v
                    },
                    expression: "pages"
                  }
                },
                _vm._l(_vm.pages, function(page, index) {
                  return _c(
                    "a-col",
                    { key: index, attrs: { xs: 24, xl: 12, xxl: 8 } },
                    [
                      _c(
                        "a-card",
                        {
                          class: {
                            "pinned-item": page.pinned
                          },
                          attrs: {
                            hoverable: "",
                            bodyStyle: Object.assign(
                              {},
                              { padding: "16px 16px 24px" },
                              _vm.isPrimaryPage(page)
                                ? { backgroundColor: "#fff7e6" }
                                : {}
                            )
                          }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { id: "checkbox" } },
                            [
                              _c("a-checkbox", {
                                attrs: {
                                  checked: _vm.selectedPages.find(function(
                                    item
                                  ) {
                                    return item.id == page.id
                                  })
                                    ? true
                                    : false
                                },
                                on: {
                                  change: function(e) {
                                    return _vm.handleSelectPage(e, page)
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "visualize-area" }, [
                            _c(
                              "div",
                              {
                                staticClass: "thumbnail",
                                attrs: { role: "button" },
                                on: {
                                  click: function() {
                                    if (page.template === "CATALOG") {
                                      _vm.handleGoTo(_vm.pathToCodes(page))
                                    }
                                  }
                                }
                              },
                              [
                                page.thumbnail && page.thumbnail.url
                                  ? _c("img", {
                                      attrs: {
                                        src: page.thumbnail.url,
                                        alt: page.thumbnail.alt
                                      }
                                    })
                                  : _c("img", {
                                      attrs: {
                                        src: require("@/assets/gray-background.png"),
                                        alt: "gray bg"
                                      }
                                    })
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "state" },
                              [
                                _vm.isPrimaryPage(page)
                                  ? _c(
                                      "a-tag",
                                      { attrs: { color: "orange" } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "tag",
                                            staticStyle: { padding: "2px 0px" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t(page.state)) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                page.pinned
                                  ? _c(
                                      "a-tag",
                                      { attrs: { color: "#2e8bce" } },
                                      [
                                        _c("a-icon", {
                                          staticClass: "tag",
                                          attrs: { type: "pushpin" }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]),
                          _c(
                            "a-row",
                            { attrs: { gutter: [16, 8] } },
                            [
                              _c(
                                "a-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "a-tag",
                                    { staticStyle: { "font-size": "15px" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("formatId")(page.id, "Page")
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("a-col", { attrs: { span: 18 } }, [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v(" " + _vm._s(page.name) + " ")
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "a-row",
                            { attrs: { gutter: [16, 8] } },
                            [
                              _c(
                                "a-col",
                                { attrs: { xs: 12 } },
                                [
                                  _c(
                                    "a-tag",
                                    {
                                      staticStyle: { cursor: "pointer" },
                                      attrs: {
                                        color: _vm.templateColorMapping(page)
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleOpenIframeReview(
                                            page
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t(page.template)) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                {
                                  staticClass: "text-right",
                                  attrs: { xs: 12 }
                                },
                                [
                                  _c("span", { staticClass: "mr-sm" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("project.grid.Path")) + ":"
                                    )
                                  ]),
                                  _c("span", [_vm._v(_vm._s(_vm.path(page)))])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-row",
                            { attrs: { gutter: [16, 8] } },
                            [
                              _c("a-col", { attrs: { xs: 12 } }, [
                                _c("span", { staticClass: "mr-sm" }, [
                                  _vm._v(_vm._s(_vm.$t("Created")) + ":")
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("dayjs")(
                                        page.created,
                                        "YYYY/MM/DD HH:mm:ss"
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _c(
                                "a-col",
                                {
                                  staticClass: "text-right",
                                  attrs: { xs: 12 }
                                },
                                [
                                  _c(
                                    "a-tag",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: {
                                        color: page.ispublic ? "green" : "red"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              page.ispublic
                                                ? "Public"
                                                : "Private"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "template",
                            { slot: "actions" },
                            [
                              _c("a-icon", {
                                attrs: { type: "edit" },
                                on: {
                                  click: function() {
                                    return _vm.handleGoTo(_vm.pathToEdit(page))
                                  }
                                }
                              }),
                              page.ispublic
                                ? _c("a-icon", {
                                    attrs: { type: "code" },
                                    on: {
                                      click: function($event) {
                                        return _vm.$emit(
                                          "onOpenIframeReview",
                                          page.id,
                                          true
                                        )
                                      }
                                    }
                                  })
                                : _c(
                                    "a-popconfirm",
                                    {
                                      attrs: {
                                        "ok-text": "OK",
                                        "cancel-text": _vm.$t("Cancel")
                                      },
                                      on: {
                                        confirm: function($event) {
                                          return _vm.$emit(
                                            "onOpenChangeStatusModel",
                                            page,
                                            {
                                              openIframeReviewAfterPublic: true
                                            }
                                          )
                                        },
                                        cancel: function() {}
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "popconfirm-title",
                                          attrs: { slot: "title" },
                                          slot: "title"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "This page is private now, you need to change it to public to embed. Are you sure to change it to public?"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c("a-icon", { attrs: { type: "code" } })
                                    ],
                                    1
                                  ),
                              _c(
                                "a-dropdown",
                                [
                                  _c("a-icon", { attrs: { type: "ellipsis" } }),
                                  _c(
                                    "a-menu",
                                    {
                                      attrs: { slot: "overlay" },
                                      slot: "overlay"
                                    },
                                    [
                                      page.template === "CATALOG"
                                        ? _c(
                                            "a-menu-item",
                                            {
                                              key: "GotoCodeList",
                                              on: {
                                                click: function($event) {
                                                  _vm.handleGoTo(
                                                    _vm.pathToCodes(page)
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("a-icon", {
                                                attrs: { type: "container" }
                                              }),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("Code list"))
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.isPrimaryPage(page)
                                        ? _c(
                                            "a-menu-item",
                                            { key: "setPrimary" },
                                            [
                                              _c(
                                                "a-popconfirm",
                                                {
                                                  attrs: {
                                                    placement: "top",
                                                    title: _vm.$t(
                                                      "Are you sure to change the primary page?"
                                                    ),
                                                    "ok-text": _vm.$t("Yes"),
                                                    "cancel-text": _vm.$t("No")
                                                  },
                                                  on: {
                                                    confirm: function($event) {
                                                      return _vm.$emit(
                                                        "onChangePrimaryPage",
                                                        page.id
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("a-icon", {
                                                    attrs: { type: "edit" }
                                                  }),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("Set as primary")
                                                      )
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "a-menu-item",
                                        {
                                          key: "Copy",
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit(
                                                "onOpenCopyPageModel",
                                                page
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("a-icon", {
                                            attrs: { type: "copy" }
                                          }),
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.$t("Copy")))
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-menu-item",
                                        {
                                          key: "Move",
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit(
                                                "onOpenMovePageModel",
                                                page
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("a-icon", {
                                            attrs: { type: "drag" }
                                          }),
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.$t("Move")))
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-menu-item",
                                        {
                                          key: "DuplicatePage",
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit(
                                                "onDuplicatePage",
                                                page
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("a-icon", {
                                            attrs: { type: "copy" }
                                          }),
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.$t("Duplicate")))
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-menu-item",
                                        {
                                          key: "Public/Private",
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit(
                                                "onOpenChangeStatusModel",
                                                page
                                              )
                                            }
                                          }
                                        },
                                        [
                                          !page.ispublic
                                            ? [
                                                _c("a-icon", {
                                                  attrs: { type: "unlock" }
                                                }),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("Public"))
                                                  )
                                                ])
                                              ]
                                            : _vm._e(),
                                          page.ispublic
                                            ? [
                                                _c("a-icon", {
                                                  attrs: { type: "lock" }
                                                }),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("Private"))
                                                  )
                                                ])
                                              ]
                                            : _vm._e()
                                        ],
                                        2
                                      ),
                                      _c(
                                        "a-menu-item",
                                        {
                                          key: "PinToTop",
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit(
                                                "onPinning",
                                                page
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("a-icon", {
                                            attrs: { type: "pushpin" }
                                          }),
                                          !page.pinned
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("Pin to top"))
                                                )
                                              ])
                                            : _c("span", [
                                                _vm._v(_vm._s(_vm.$t("Unpin")))
                                              ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-menu-item",
                                        {
                                          key: "Export",
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit("onExport", page)
                                            }
                                          }
                                        },
                                        [
                                          _c("a-icon", {
                                            attrs: { type: "export" }
                                          }),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("Export page"))
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                }),
                1
              )
            : _c("p", { staticClass: "mt-md text-center" }, [
                _vm._v(" " + _vm._s(_vm.$t("No data")) + " ")
              ])
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "16px", "text-align": "right" } },
        [
          _c("a-pagination", {
            attrs: {
              current: _vm.pagination.current,
              "show-size-changer": "",
              "page-size-options": ["10", "20", "24", "30", "40", "50"],
              "page-size": _vm.pagination.pageSize,
              total: _vm.pagination.total,
              "show-total": function(total) {
                return _vm.$t("Total") + " " + total
              }
            },
            on: {
              "update:pageSize": function($event) {
                return _vm.$set(_vm.pagination, "pageSize", $event)
              },
              "update:page-size": function($event) {
                return _vm.$set(_vm.pagination, "pageSize", $event)
              },
              change: _vm.handlePageChange,
              showSizeChange: _vm.handlePageChange
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }