var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: _vm.visible,
        closable: false,
        footer: null,
        title: null,
        width: 400
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "mb-sm",
          staticStyle: { "font-weight": "500", "font-size": "1rem" }
        },
        [
          _c("a-icon", {
            staticStyle: { color: "#faad14", "font-size": "1.2rem" },
            attrs: { type: "question-circle" }
          }),
          _c("span", { staticClass: "ml-xs" }, [
            _vm._v(_vm._s(_vm.$t("Duplicate page")))
          ])
        ],
        1
      ),
      _vm.isCatalogPage
        ? _c(
            "a-row",
            { staticClass: "mb-sm", attrs: { type: "flex", align: "middle" } },
            [
              _c(
                "a-checkbox",
                {
                  attrs: { defaultChecked: _vm.isDuplicateCodes },
                  on: {
                    change: function(target) {
                      return (_vm.isDuplicateCodes = target.checked)
                    }
                  }
                },
                [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("Include all codes inside the page?")) +
                        " "
                    )
                  ])
                ]
              )
            ],
            1
          )
        : _c("a-row", { staticClass: "mb-sm" }, [
            _c("span", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("Do you want to duplicate this page?")) +
                  " "
              )
            ])
          ]),
      _c(
        "a-row",
        {
          attrs: {
            gutter: [8, 32],
            type: "flex",
            justify: "end",
            align: "middle"
          }
        },
        [
          _c(
            "a-col",
            { attrs: { xs: 24, lg: 7 } },
            [
              _c(
                "a-button",
                {
                  staticStyle: { width: "100%" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 24, lg: 7 } },
            [
              _c(
                "a-button",
                {
                  staticStyle: { width: "100%" },
                  attrs: { type: "primary" },
                  on: { click: _vm.handleDuplicate }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Yes")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }