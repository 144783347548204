//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    page: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isDuplicateCodes: false
    };
  },

  computed: {
    isCatalogPage() {
      return this.page?.template === 'CATALOG';
    }
  },

  methods: {
    handleDuplicate() {
      this.$emit('ok', this.page.id, { isDuplicateCodes: this.isDuplicateCodes });
    }
  }
};
