//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions, mapMutations } from 'vuex';
import { PAGE_STATE } from '@/enum';

export default {
  components: {},

  props: {
    rowData: { type: Array, require: true, default: () => [] },
    pagination: { type: Object, require: true, default: () => ({}) },
    selectedPages: { type: Array, require: true, default: () => [] }
  },

  data() {
    return {
      loading: false,
      pages: [],
      selectedPage: null,
      choosePageModalVisible: false,
      pageChoosingActionType: '',
      publicPageSettingModalVisible: false,
      categorySettingModalVisible: false,
      advancedInfoSettingModalVisible: false
    };
  },

  computed: {
    ...mapGetters('user', ['userInfo']),
    ...mapGetters('project', ['projectById']),

    pageId() {
      return this.$route.params.pageId;
    },
    projectId() {
      return this.$route.params.projectId;
    },
    isSelectedPage() {
      return this.selectedPages.length > 0;
    }
  },
  watch: {
    rowData: {
      handler(newValue) {
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
        this.pages = newValue;
      },
      immediate: true
    }
  },
  mounted() {},

  methods: {
    ...mapMutations('page', ['UPDATE_ORDER']),
    ...mapActions('page', ['loadPages', 'reset']),
    handleSelectPage({ target }, page) {
      let newSelectedPages = [...this.selectedPages];
      if (target.checked) {
        newSelectedPages.push(page);
      } else {
        newSelectedPages = newSelectedPages.filter(item => item.id !== page.id);
      }
      this.$emit('onSeleted', { data: newSelectedPages }, 'card-view');
    },
    async dragPagesEnd(event) {
      const { oldIndex, newIndex } = event;
      const previousRowDatas = this.rowData;
      const currentRowDatas = this.pages;
      let orderBy = 0;
      // WARNING: This function is serve different purpose: TOP-PAGE and Pinned page is alway in TOP position
      const unDraggableOnTopItem = previousRowDatas.filter(
        item => item.state === PAGE_STATE.PRIMARY || item.pinned
      );
      // Return if the drag and drop position is the same
      if (newIndex === oldIndex) return;
      try {
        // Return if the drag and drop position is belong to the unDraggableOnTopItem
        if (
          unDraggableOnTopItem.some(
            item =>
              item.id === previousRowDatas[oldIndex].id || item.id === previousRowDatas[newIndex].id
          )
        ) {
          throw new Error(
            this.$t('This page is pinned or primary page. You can not drag and drop it.')
          );
        }

        // Get target before the row data ORDER is changed by drag and drop
        const targetCodeOrderBy = previousRowDatas[newIndex].order_by;
        const firstCodeOrderBy = currentRowDatas[newIndex + 1]?.order_by || 0;
        const secondCodeOrderBy = currentRowDatas[newIndex - 1]?.order_by || 0;
        if (newIndex === unDraggableOnTopItem.length) {
          // Drag and drop to the first position (after the unDraggableOnTopItem)
          orderBy = targetCodeOrderBy + 0.5;
        } else if (newIndex === currentRowDatas.length - 1) {
          // Drag and drop to the last position
          orderBy = targetCodeOrderBy - 0.5;
        } else {
          // Drag and drop to the middle position
          orderBy = (firstCodeOrderBy + secondCodeOrderBy) / 2;
        }
        // Update the order by
        const editedPage = await this.$s.api.page.editPageById(
          this.projectId,
          currentRowDatas[newIndex].id,
          {
            order_by: orderBy
          }
        );
        // Update the order by in store
        this.UPDATE_ORDER({ id: editedPage.id, order_by: orderBy });
        this.reset();
        this.loadPages({ payload: currentRowDatas });
        this.$notification.success({ message: this.$t('Successful') });
      } catch (error) {
        this.$notification.error({
          message: error.message
        });
        this.$emit('onReload');
      }
    },
    handlePageChange(page, pageSize) {
      this.$emit('onPageChange', { page, pageSize });
    },
    handleGoTo(path) {
      this.$router.push(path);
    },
    pathToEdit(page) {
      return `/projects/${this.projectId}/pages/${page.id}`;
    },
    pathToCodes(page) {
      return `/projects/${this.projectId}/pages/${page.id}/codes`;
    },
    path(page) {
      return page?.state === PAGE_STATE.PRIMARY ? `/` : `/${page?.slug || page?.id}`;
    },
    isPrimaryPage(page) {
      return page?.state === PAGE_STATE.PRIMARY;
    },
    templateColorMapping(page) {
      const mapping = {
        CATALOG: 'blue',
        PORTAL: 'purple'
      };
      return mapping[page.template];
    },
    handleOpenIframeReview(page) {
      this.$emit('onOpenIframeReview', page.id, true);
    }
  }
};
