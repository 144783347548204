var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "page-header",
        {
          staticClass: "mb-xs",
          attrs: {
            "show-back-icon": "",
            title: _vm.currentProject.name,
            "show-status": "",
            status: _vm.currentProject.is_public
          },
          on: { back: _vm.handleBackToProjectList }
        },
        [
          _c(
            "template",
            { slot: "extra" },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary", icon: "plus" },
                  on: { click: _vm.goToPageCompose }
                },
                [_vm._v(_vm._s(_vm.$t("Page compose")))]
              ),
              _c(
                "a-button",
                {
                  staticClass: "ml-xs",
                  attrs: { icon: "import" },
                  on: { click: _vm.openPageImport }
                },
                [_vm._v(_vm._s(_vm.$t("Import page")))]
              ),
              _c(
                "a-dropdown",
                { staticClass: "ml-xs", attrs: { trigger: ["click"] } },
                [
                  _c(
                    "a-menu",
                    { attrs: { slot: "overlay" }, slot: "overlay" },
                    [
                      _c(
                        "a-menu-item",
                        {
                          key: "handleProjectExport",
                          on: { click: _vm.handleProjectExport }
                        },
                        [
                          _c("a-icon", { attrs: { type: "export" } }),
                          _c("span", [_vm._v(_vm._s(_vm.$t("Export project")))])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-button", { attrs: { icon: "ellipsis" } })
                ],
                1
              )
            ],
            1
          )
        ],
        2
      ),
      _c(
        "search-box",
        {
          staticClass: "pb-sm",
          attrs: { queryParam: _vm.queryParam },
          on: {
            changeTitle: _vm.changeTitle,
            changeSlug: _vm.changeSlug,
            handleSearch: _vm.handleSearch,
            toggleView: _vm.onChangeView,
            handleReset: _vm.handleReset
          }
        },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _c(
                "a-button",
                {
                  staticClass: "mr-xs",
                  attrs: { icon: "inbox", disabled: !_vm.isSelectedPage },
                  on: { click: _vm.openArchiveModal }
                },
                [_vm._v(_vm._s(_vm.$t("Archive")) + " ")]
              ),
              _c(
                "a-button",
                {
                  staticClass: "mr-xs",
                  attrs: { icon: "eye", disabled: !_vm.isSelectedPage },
                  on: {
                    click: function($event) {
                      _vm.publicSettingModalVisible = true
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Switch public/private")))]
              ),
              _c(
                "a-button",
                {
                  staticClass: "mr-xs",
                  attrs: { icon: "copy", disabled: !_vm.isSelectedPage },
                  on: { click: _vm.handleCopyPageOpening }
                },
                [_vm._v(_vm._s(_vm.$t("Copy")))]
              ),
              _c(
                "a-button",
                {
                  attrs: { icon: "drag", disabled: !_vm.isSelectedPage },
                  on: { click: _vm.handleMovePageOpening }
                },
                [_vm._v(_vm._s(_vm.$t("Move")))]
              )
            ],
            1
          )
        ],
        2
      ),
      _c("a-spin", { attrs: { spinning: _vm.loading } }, [
        _c(
          "div",
          { staticClass: "spin-content" },
          [
            false
              ? _c("CardView", {
                  attrs: {
                    rowData: _vm.pages,
                    pagination: _vm.pagination,
                    selectedPages: _vm.selectedPages
                  },
                  on: {
                    onPageChange: _vm.handleChangePage,
                    onDuplicatePage: _vm.onOpenDuplicatePageModalFromChild,
                    onOpenIframeReview: _vm.handleToggleIframeReviewFromChild,
                    onOpenChangeStatusModel:
                      _vm.onOpenChangeStatusModelFromChild,
                    onChangePrimaryPage: _vm.handleChangePrimaryPageFromChild,
                    onOpenCopyPageModel: _vm.handleOpenCopyPageModelFromChild,
                    onOpenMovePageModel: _vm.handleOpenMovePageModelFromChild,
                    onPinning: _vm.handlePinToTopFromChild,
                    onExport: _vm.handlePageExportFromChild,
                    onSeleted: _vm.handleSelectPage,
                    onReload: _vm.getData
                  }
                })
              : _vm._e(),
            _c("SGrid", {
              ref: "sGrid",
              attrs: {
                "grid-name": "page",
                rowData: _vm.pages,
                enableCheckbox: true,
                columnDefs: _vm.columnDefs,
                gridContext: _vm.context,
                pagination: _vm.pagination,
                getRowClass: _vm.getRowClass,
                rowDragText: _vm.rowDragText,
                rowHeight: 124
              },
              on: {
                onGridReady: _vm.handleGridReady,
                onPageChange: _vm.handleChangePage,
                onRowDragEnd: _vm.rowDragEnd,
                onRowDragEnter: _vm.rowDragEnter,
                selectionChanged: _vm.handleSelectPage
              }
            })
          ],
          1
        )
      ]),
      _vm.iframeVisible
        ? _c(
            "div",
            [
              _vm.iframeVisible && _vm.interactedPage.template === "PORTAL"
                ? _c("EditPortalWebsiteRenderer", {
                    ref: "editWebsite",
                    attrs: {
                      visible: _vm.iframeVisible,
                      page: _vm.interactedPage
                    },
                    on: {
                      cancel: function($event) {
                        return _vm.handleToggleIframeReviewFromChild(
                          _vm.interactedPage.id,
                          false
                        )
                      },
                      "publish-new-version": _vm.handleAlertPublishNewVersion
                    }
                  })
                : _c("iframe-review", {
                    ref: "editWebsite",
                    attrs: {
                      visible: _vm.iframeVisible,
                      page: _vm.interactedPage
                    },
                    on: {
                      cancel: function($event) {
                        return _vm.handleToggleIframeReviewFromChild(
                          _vm.interactedPage.id,
                          false
                        )
                      },
                      "publish-new-version": _vm.handleAlertPublishNewVersion
                    }
                  })
            ],
            1
          )
        : _vm._e(),
      _vm.pageArchiveVisible
        ? _c("ArchiveModal", {
            attrs: {
              visible: _vm.pageArchiveVisible,
              "row-data": _vm.selectedPages,
              title: _vm.$t("Do you want to archive the selected page?")
            },
            on: {
              cancel: function() {
                _vm.pageArchiveVisible = false
              },
              ok: _vm.handlePageArchiving
            }
          })
        : _vm._e(),
      _vm.pageImportVisible
        ? _c("ImportModal", {
            attrs: {
              visible: _vm.pageImportVisible,
              title: "Import page",
              type: "page"
            },
            on: {
              cancel: function() {
                _vm.pageImportVisible = false
              },
              ok: _vm.handlePageImporting
            }
          })
        : _vm._e(),
      _c("ChooseProjectModal", {
        attrs: {
          "action-type": _vm.projectChoosingActionType,
          visible: _vm.chooseProjectModalVisible,
          projects: _vm.projects
        },
        on: {
          copy: function(payload) {
            return _vm.handleCopyPagesFromChild(
              Object.assign({}, payload, { pageIds: _vm.selectedPageIds })
            )
          },
          move: function(payload) {
            return _vm.handleMovePagesFromChild(
              Object.assign({}, payload, { pageIds: _vm.selectedPageIds })
            )
          },
          cancel: function($event) {
            _vm.chooseProjectModalVisible = false
          }
        }
      }),
      _vm.publicSettingModalVisible
        ? _c(
            "PublicSettingModal",
            {
              attrs: {
                visible: _vm.publicSettingModalVisible,
                loading: _vm.loading
              },
              on: {
                cancel: function($event) {
                  ;(_vm.publicSettingModalVisible = false),
                    (_vm.checkedModalPublic = false)
                },
                change: function(event) {
                  _vm.checkedModalPublic = event.checked
                },
                ok: _vm.updatePublicSetting
              }
            },
            [
              _c(
                "div",
                { attrs: { slot: "content" }, slot: "content" },
                [
                  _vm.isChangeProjectStatusWarning && _vm.checkedModalPublic
                    ? _c(
                        "a-row",
                        {
                          staticClass: "mt-sm",
                          attrs: { type: "flex", align: "middle" }
                        },
                        [
                          _c(
                            "span",
                            { staticStyle: { color: "#eca30c" } },
                            [
                              _c("a-icon", { attrs: { type: "info-circle" } }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "This action will change the visibility of the parent project of selected pages to public. Do you want to continue?"
                                    )
                                  ) +
                                  " "
                              )
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.isContainCatalogPage
                    ? _c(
                        "a-row",
                        { staticClass: "mt-sm", attrs: { type: "flex" } },
                        [
                          _c(
                            "a-col",
                            {
                              staticStyle: { color: "#eca30c" },
                              attrs: { span: 24 }
                            },
                            [
                              _c("a-icon", { attrs: { type: "info-circle" } }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("Containing catalog pages")) +
                                  " "
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-checkbox",
                            {
                              attrs: { defaultChecked: _vm.isIncludeCodes },
                              on: {
                                change: function(ref) {
                                  var target = ref.target

                                  _vm.isIncludeCodes = target.checked
                                }
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "Include all codes inside the catalog pages?"
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _c("DuplicateModal", {
        attrs: { visible: _vm.duplicateModalVisible, page: _vm.interactedPage },
        on: {
          cancel: function($event) {
            _vm.duplicateModalVisible = false
          },
          ok: _vm.handleDuplicatePage
        }
      }),
      _c("ChangeStatusModal", {
        attrs: {
          visible: _vm.changeStatus.visible,
          page: _vm.interactedPage,
          loading: _vm.changeStatus.loading,
          "have-publish-version": _vm.changeStatus.havePublishVersion
        },
        on: {
          cancel: function($event) {
            _vm.changeStatus.visible = false
          },
          ok: _vm.handleChangeStatusFromChild,
          onOpenIframeReview: _vm.handleToggleIframeReviewFromChild
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }